import './styles.css';

import { Link, Typography, createMuiTheme } from '@material-ui/core';

import Logo from './images/logo.svg';
import FactsPageBannerImage from './images/facts_page_banner.jpg';
import HomePageBannerImage from './images/home_page_banner.jpg';
import HomePageSectionImage from './images/home_page_section_1.png';
import ImpactLogo from './images/Impact_logo.png';
import PrivacyPolicyPDF from './VillaFrizzoniPrivacyPolicy.pdf';
import React from 'react';
import { getYear } from '../../src/util/getYear';

const headingFontFamily = `"One Dot Condensed Bold", Helvetica`;
const bodyFontFamily = `"One Dot", Helvetica`;
const buttonBorderRadius = 4;
const primaryColor = '#cb2c30';
const secondaryColor = '#551c25';

const defaultTheme = createMuiTheme();

const theme = createMuiTheme({
  palette: {
    primary: {
      main: primaryColor,
    },
    secondary: {
      main: secondaryColor,
    },
  },
  typography: {
    fontFamily: bodyFontFamily,
    fontWeightMedium: 600,
    useNextVariants: true,
    htmlFontSize: 16,
    h1: {
      fontFamily: headingFontFamily,
      fontWeight: '400',
      fontSize: '48px',
      lineHeight: 1.2,
      letterSpacing: '-.9px',
      textTransform: 'uppercase',
      color: '#000000',
    },
    h2: {
      fontFamily: headingFontFamily,
      fontWeight: '400',
      fontSize: '40px',
      lineHeight: 1.4,
      letterSpacing: '0px',
    },
    h3: {
      fontFamily: headingFontFamily,
      fontWeight: '400',
      fontSize: '32px',
      lineHeight: 1.4,
      letterSpacing: '0px',
    },
    h4: {
      fontFamily: headingFontFamily,
      fontWeight: '400',
      fontSize: '24px',
      lineHeight: 1.4,
      letterSpacing: '0.25px',
    },
    h5: {
      fontFamily: headingFontFamily,
      fontWeight: '700',
      fontSize: '20px',
      lineHeight: 1.4,
      letterSpacing: '0.25px',
    },
    h6: {
      fontFamily: headingFontFamily,
      fontWeight: '600',
      fontSize: '18px',
      lineHeight: 1.4,
      letterSpacing: '0.25px',
    },
    subtitle1: {
      fontWeight: '600',
      fontSize: '17px',
      lineHeight: 1.4,
      letterSpacing: '0.15px',
    },
    subtitle2: {
      fontWeight: '600',
      fontSize: '15px',
      lineHeight: 1.4,
      letterSpacing: '0.1px',
    },
    body1: {
      fontWeight: 'normal',
      fontSize: '17px',
      lineHeight: 1.4,
      letterSpacing: '0.5px',
    },
    body2: {
      fontWeight: 'normal',
      fontSize: '15px',
      lineHeight: 1.4,
      letterSpacing: '0.25px',
    },
    caption: {
      fontWeight: 'normal',
      fontSize: '14px',
      lineHeight: 1.4,
      letterSpacing: '0.4px',
    },
    overline: {
      fontWeight: '700',
      fontSize: '14px',
      lineHeight: 1.4,
      letterSpacing: '1.25px',
    },
    button: {
      ...defaultTheme.typography.button,
      fontFamily: headingFontFamily,
      fontSize: '16px',
    },
  },
  overrides: {
    MuiButton: {
      root: {
        borderRadius: buttonBorderRadius,
      },
    },
    MuiPaper: {
      rounded: {
        borderRadius: buttonBorderRadius,
      },
    },
    MuiAccordion: {
      rounded: {
        '&:first-child': {
          borderTopLeftRadius: buttonBorderRadius,
          borderTopRightRadius: buttonBorderRadius,
        },
        '&:last-child': {
          borderBottomLeftRadius: buttonBorderRadius,
          borderBottomRightRadius: buttonBorderRadius,
        },
      },
    },
  },
});

// eslint-disable-next-line import/no-anonymous-default-export
export default {
  id: 'villafrizzoni',
  name: `Villa Frizzoni`,
  logo: Logo,
  styles: {
    muiTheme: {
      ...theme,
    },
    buttonBorderRadius: buttonBorderRadius,
  },
  appName: '',
  // meta tag values are used to generate the link preview when sharing link on social media
  metaTags: {
    title: `Villa Frizzoni Recycling`,
    description: ``,
    url: '',
    thumbnailImage: '',
  },
  footer: {
    text: `© ${getYear()} Villa Frizzoni® is a registered trademark of Independent Marketing Alliance.`,
    privacyPolicyUrl: PrivacyPolicyPDF,
  },
  homePage: {
    bannerImage: HomePageBannerImage,
    headline: 'Did you know pizza boxes are recyclable?',
    body: (
      <Typography variant='body1' color='textPrimary'>
        Villa Frizzoni pizza boxes are recyclable! Grease and food residue does not prevent our boxes from being recycled. Since some recycling programs might have confusing rules, we have partnered with our box manufacturer to help you find your local guidelines.
      </Typography>
    ),
    sectionImage: HomePageSectionImage,
    recyclingGuidelinesInstructions: 'Use our lookup tool at the top of the page to find out what the pizza box recycling guidelines in your area.'
  },
  factsPage: {
    banner: {
      bannerImage: FactsPageBannerImage,
      headline: 'Understanding Pizza Box Recycling',
    },
    headline: `MAKE A STRONGER IMPACT`,
    optionalLongFact: {
      headline: `Sustainable Carryout and Delivery Solutions`,
      sidebarContent: (
        <>
          <img src={ImpactLogo} alt='Impact Logo' width="250px" style={{ marginBottom: '15px' }}></img>
          <Typography variant='h5' style={{ textTransform: 'uppercase', marginBottom: '10px' }}>
            Make a stronger impact with every to-go order.
          </Typography>
          <Typography variant='body2'>
            ProPak IMPACT Responsibly Sourced Goods combines proven performance with measurable eco-friendly benefits.
          </Typography>
        </>
      ),
      content: (
        <>
          <Typography variant='body1' gutterBottom>
            Along with Villa Frizzoni's recyclable pizza boxes, customers can also help protect the environment by using our Propak IMPACT products. A wide range of containers, cups and plates that are recyclable, compostable, or made from recycled content.
          </Typography>
        </>
      ),
    },
  },
  faqsPage: {
  },
  emailFeedback: {
    isSupported: false,
    privacy: null,
  },
  customTheme: {
    header: {
      backgroundColor: 'black',
    },
  }
};
